//
// Include bootstrap
//
// override primary color as a demo:
$theme-colors: (
        "primary": #007bcc,
);

@import "../../../node_modules/bootstrap/scss/bootstrap";

//
// Include font-awesome
//

//$fa-font-path: "~font-awesome/fonts";
//@import '../../../node_modules/font-awesome/scss/font-awesome';

//
// import tempusdominus-bootstrap-4
//
@import "../../../node_modules/tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css";

body {

  tr.clickable-row {
    cursor: pointer;
    color: #007bcc;
  }

  h1, a[role="button"], a[class="btn btn-danger"], button[type="submit"], button[type="button"] {
    font-style: italic
  }

  .card .card-body {
    padding-top:10px;
    padding-bottom:10px;
    margin-top: 10px;
    margin-bottom:2px;
  }

  .card .card-header {
    padding-top:7px;
    padding-bottom:7px;
    margin-top: 0px;
    margin-bottom:0px;
  }

  .top-buffer {
    padding-top: 10px;
  }

  .bottom-buffer {
    padding-bottom: 10px;
  }

  .top-border {
    border-top: 3px solid grey;
  }

  .icon-holder {
    display: flex;
  }

  .floating-icon {
    padding-right: 10px;
    height: 100%;
  }

  .codeenabled {
    font-weight: bold;
  }

  .codedisabled {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: line-through;
  }

  .utilization-holder {
    width: 100%;
    height: 5px;
  }

  .utilization-utilized {
    float: left; height: 5px; background-color: #007bcc;
  }

  .utilization-available {
    float: left; height: 5px; background-color: #6c757d;
  }

}

.navbar-brand {
  background: url('logo.svg') no-repeat left center;
  background-size: contain;
  display: block;
  width: 8rem;
  height: 2.75rem;
  text-indent:-9999px;
  margin:0.25rem 0.5rem 0.25rem 0;
}

@media print {
  body {
    font-family: sans-serif;
  }
  .not-printable {
    display: none;
  }
}